import { useContext } from 'react';
import { QAPanelContext } from '@/context/qa-panel-context-provider/QAPanelContextProvider';

export const useQAPanelContext = () => {
  const context = useContext(QAPanelContext);

  if (!context) {
    throw new Error('Context data could not be loaded.');
  }

  return context;
};
