import Icon from '@/components/base/icon';
import { ICheckbox } from './types';
import { functions } from '@wap-client/core';

const Checkbox: React.FC<ICheckbox> = ({
  label,
  value,
  checked,
  onChange,
  className,
  ...props
}) => {
  const onChangeBase = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(value, e);
  };

  return (
    <label
      className={functions.classnames(className, 'qa-panel-checkbox-group')}
      {...props}
    >
      <input type="checkbox" checked={checked} onChange={onChangeBase} />
      <Icon name="icon-check-mini" className="qa-panel-checkbox-group-slot" />
      <div className="qa-panel-checkbox-group-text">{label}</div>
    </label>
  );
};

export default Checkbox;
