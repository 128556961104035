import React, { createContext, useMemo, useState } from 'react';
import { IQAPanelContextProviderProps } from './types';
import {
  IQAQuestionAnswers,
  IStep,
  IStepAnswer,
} from '@/components/widgets/header/qa-panel/types';
import { fakeDelay } from '@/utils';

export const QAPanelContext = createContext<{
  isHidding: boolean;
  steps: IStep[];
  activeStep: null | IStep;
  currentStepIndex: number;
  setCurrentStepIndex: any;
  saveQuestionAnswers: (stepId: string, selectedAnswers: IStepAnswer[]) => void;
  savedQuestionsAnswers: IQAQuestionAnswers;
  isAnswerSelected: (stepId: string, answer: IStepAnswer) => boolean;
  handleCloseQAPanel: () => void;
  handleOpenQAPanel: () => void;
  handleGoNextStep: () => void;
  handleGoPrevStep: () => void;
}>({
  isHidding: false,
  steps: [],
  activeStep: null,
  currentStepIndex: 0,
  setCurrentStepIndex: null,
  saveQuestionAnswers: () => null,
  savedQuestionsAnswers: {},
  isAnswerSelected: () => false,
  handleCloseQAPanel: () => null,
  handleOpenQAPanel: () => null,
  handleGoNextStep: () => null,
  handleGoPrevStep: () => null,
});

const QAPanelContextProvider: React.FC<IQAPanelContextProviderProps> = ({
  data: { steps },
  setIsShowQAPanel,
  children,
}) => {
  const [isHidding, setHidding] = useState<boolean>(false);
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(0);
  const [savedQuestionsAnswers, setSavedQuestionsAnswers] =
    useState<IQAQuestionAnswers>({});

  const activeStep = useMemo(
    () => ({
      ...steps[currentStepIndex],
      isFirst: currentStepIndex === 0,
      isLast: currentStepIndex + 1 === steps.length,
    }),
    [currentStepIndex, steps]
  );

  const handleCloseQAPanel = () => {
    setHidding(true);
    setTimeout(() => {
      setIsShowQAPanel(false);
      setHidding(false);
    }, 750);
  };

  const handleOpenQAPanel = () => {
    setHidding(true);
    setTimeout(() => {
      setIsShowQAPanel(true);
      setHidding(false);
    }, 750);
  };

  const handleGoNextStep = async () => {
    await fakeDelay(300);

    const nextIndex = currentStepIndex + 1;
    if (steps[nextIndex]) {
      setCurrentStepIndex(nextIndex);
    }
  };

  const handleGoPrevStep = async () => {
    await fakeDelay(300);

    const nextIndex = currentStepIndex - 1;

    if (steps[nextIndex]) {
      setCurrentStepIndex(nextIndex);
    }
  };

  const saveQuestionAnswers = (
    stepId: string,
    selectedAnswers: IStepAnswer[]
  ) => {
    setSavedQuestionsAnswers((savedQuestionsAnswers) => ({
      ...savedQuestionsAnswers,
      [stepId]: selectedAnswers,
    }));
  };

  const isAnswerSelected = (stepId: string, answer: IStepAnswer) => {
    const question = savedQuestionsAnswers[stepId];

    if (question) {
      return !!question.find((savedAnswer) => savedAnswer.id === answer.id);
    }

    return false;
  };

  const value = {
    isHidding,
    steps,
    activeStep,
    currentStepIndex,
    setCurrentStepIndex,
    saveQuestionAnswers,
    savedQuestionsAnswers,
    isAnswerSelected,
    handleCloseQAPanel,
    handleGoNextStep,
    handleGoPrevStep,
    handleOpenQAPanel,
  };

  return (
    <QAPanelContext.Provider value={value}>{children}</QAPanelContext.Provider>
  );
};

export default QAPanelContextProvider;
