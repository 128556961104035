import React, { useMemo } from 'react';
import Icon from '@/components/base/icon';
import { IStep } from '../types';
import { IQAPanelStepsProgressBarProps } from './types';
import { functions } from '@wap-client/core';

const QAPanelStepsProgressBar: React.FC<IQAPanelStepsProgressBarProps> = ({
  steps,
  activeStep,
}) => {
  const activeStepIndex = useMemo(
    () => steps.findIndex((s) => s.id === activeStep.id),
    [steps, activeStep]
  );

  return (
    <div className="qa-panel-steps-progress-bar">
      {steps.map((step: IStep, index: number) => {
        const isLast = index === steps.length - 1;
        const isPassed = activeStepIndex > index;
        const isActive = activeStepIndex === index;

        return (
          <React.Fragment key={step.id}>
            <button
              className={functions.classnames(
                'qa-panel-steps-progress-bar-item',
                {
                  'qa-panel-steps-progress-bar-item--passed': isPassed,
                  'qa-panel-steps-progress-bar-item--active': isActive,
                }
              )}
            >
              {step.title}
              <span>
                {step.badgeIconClasses ? (
                  <Icon name={step.badgeIconClasses} size="xsmall" />
                ) : (
                  index + 1
                )}
              </span>
            </button>
            {!isLast && (
              <div className="qa-panel-steps-progress-bar-item-dots">
                <span />
                <span />
                <span />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default QAPanelStepsProgressBar;
