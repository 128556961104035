import React, { useLayoutEffect, useMemo, useState } from 'react';
import { IQAPanelProps } from './types';
import { functions } from '@wap-client/core';
import { QAPanelStepQuestions, QAPanelStepResult } from './step';
import { QA_PANEL_WAP_MOCK_DATA } from './mock-data';
import QAPanelContextProvider from '@/context/qa-panel-context-provider/QAPanelContextProvider';
import { useQAPanelContext } from '@/hooks/useQAPanelContext';

const QAPanel: React.FC<IQAPanelProps> = ({
  setIsShowQAPanel,
  isShowQAPanel,
}) => {
  const { handleCloseQAPanel, activeStep, isHidding } = useQAPanelContext();

  useLayoutEffect(() => {
    if (isShowQAPanel) {
      document.body.classList.add('disabled-scroll');
    }

    return () => {
      document.body.classList.remove('disabled-scroll');
    };
  }, [isShowQAPanel]);

  useLayoutEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseQAPanel();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => window.removeEventListener('keydown', handleEscKey);
  }, []);

  if (!activeStep) {
    return null;
  }

  return (
    <div
      className={functions.classnames('qa-panel', {
        'qa-panel--show': isShowQAPanel,
        'qa-panel--hide': isHidding,
      })}
    >
      {(() => {
        switch (activeStep.type) {
          case 'questions': {
            return (
              <QAPanelStepQuestions
                key={activeStep.id}
                activeStep={activeStep}
              />
            );
          }

          case 'result':
          default: {
            return (
              <QAPanelStepResult key={activeStep.id} activeStep={activeStep} />
            );
          }
        }
      })()}
    </div>
  );
};

const QAPanelWithContext: React.FC<IQAPanelProps> = (props) => {
  if (!props.isShowQAPanel) {
    return null;
  }

  return (
    <QAPanelContextProvider
      data={QA_PANEL_WAP_MOCK_DATA}
      setIsShowQAPanel={props.setIsShowQAPanel}
    >
      <QAPanel {...props} />
    </QAPanelContextProvider>
  );
};

export default QAPanelWithContext;
