import React, { useEffect, useState } from 'react';
import { IQuestionsProps } from './types';
import { functions } from '@wap-client/core';
import { Container } from '@/components/base/gridview';
import Icon from '@/components/base/icon';
import CustomButton from '@/components/widgets/custom-button';
import QAPanelStepsProgressBar from '../../steps-progress-bar';
import { IStepAnswer } from '../../types';
import { useQAPanelContext } from '@/hooks/useQAPanelContext';
import Checkbox from '@/components/widgets/header/qa-panel/checkbox';

const QAPanelStepQuestions: React.FC<IQuestionsProps> = ({ activeStep }) => {
  const [hiddingTransition, setHiddingTransition] = useState<boolean>(false);
  const [required, setRequired] = useState<boolean>(false);

  const {
    steps,
    handleCloseQAPanel,
    handleGoPrevStep,
    handleGoNextStep,
    saveQuestionAnswers,
    savedQuestionsAnswers,
    isAnswerSelected,
  } = useQAPanelContext();

  const currentQuestionAnswers = savedQuestionsAnswers[activeStep.id];

  const onChangeCheckbox = (selectedAnswer: IStepAnswer) => {
    setRequired(false);

    if (!currentQuestionAnswers) {
      saveQuestionAnswers(activeStep.id, [selectedAnswer]);
      return;
    }

    const existsSelectedAnswer = currentQuestionAnswers.find(
      (item) => item.id === selectedAnswer.id
    );

    if (existsSelectedAnswer) {
      const newAnswers = currentQuestionAnswers.filter(
        (item) => item.id !== selectedAnswer.id
      );
      saveQuestionAnswers(activeStep.id, newAnswers);
      return;
    }

    if (activeStep.content.multiselect === true) {
      saveQuestionAnswers(activeStep.id, [
        ...currentQuestionAnswers,
        selectedAnswer,
      ]);
      return;
    }

    saveQuestionAnswers(activeStep.id, [selectedAnswer]);
  };

  const onGoBack = () => {
    if (activeStep.isFirst) {
      handleCloseQAPanel();
    }

    setHiddingTransition(true);
    handleGoPrevStep();
  };

  const onSubmit = () => {
    if (!currentQuestionAnswers || currentQuestionAnswers.length === 0) {
      setRequired(true);
      return;
    }

    setHiddingTransition(true);
    handleGoNextStep();
  };

  return (
    <div
      key={activeStep.id}
      className={functions.classnames(
        'qa-panel-content qa-panel-content--questions qa-panel-content-transition',
        {
          'qa-panel-content-transition--hidding': hiddingTransition,
        }
      )}
    >
      <Container className="qa-panel-container" size="full">
        <header className="qa-panel-header">
          <button className="qa-panel-header-back-btn" onClick={onGoBack}>
            <Icon name="icon-arrow-left" />
          </button>
          <div className="qa-panel-header-title">{activeStep.header.title}</div>
          <button
            className="qa-panel-header-close-btn"
            onClick={handleCloseQAPanel}
          >
            <Icon name="icon-close-2" />
          </button>
        </header>
        <Container className="qa-panel-main" size="wide">
          <QAPanelStepsProgressBar steps={steps} activeStep={activeStep} />
          <div className="qa-panel-question">
            <div className="qa-panel-question-title">
              {activeStep.content.title}
            </div>
            {activeStep.content.answers && (
              <div className="qa-panel-question-answers">
                {activeStep.content.answers.map((answer: IStepAnswer) => (
                  <Checkbox
                    key={answer.id}
                    className={functions.classnames(
                      'qa-panel-question-answers-item',
                      {
                        'qa-panel-question-answers-item--active':
                          isAnswerSelected(activeStep.id, answer),
                        'qa-panel-question-answers-item--error': required,
                      }
                    )}
                    label={answer.text}
                    value={answer}
                    checked={isAnswerSelected(activeStep.id, answer)}
                    onChange={onChangeCheckbox}
                  />
                ))}
              </div>
            )}
          </div>
          <CustomButton
            className="qa-panel-progress-btn"
            round={8}
            onClick={onSubmit}
          >
            Next
          </CustomButton>
        </Container>
      </Container>
    </div>
  );
};

export default QAPanelStepQuestions;
