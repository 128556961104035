import React, { useMemo } from 'react';
import { IStepAnswer } from '../types';
import { useQAPanelContext } from '@/hooks/useQAPanelContext';
import useScrollWheel from '@/hooks/useScrollWheel';

const QAPanelSummaryAnswersBar: React.FC = () => {
  const { steps, savedQuestionsAnswers } = useQAPanelContext();
  const scrollRef = useScrollWheel<HTMLDivElement>();

  const formattedAnswersSummary = useMemo(() => {
    const answersSummary: string[] = [];

    Object.entries(savedQuestionsAnswers).forEach(([stepId, answers]) => {
      const selectedStep = steps.find((step) => step.id === stepId);
      if (selectedStep) {
        const selectedStepIndex = steps.findIndex((step) => step.id === stepId);
        answers.forEach((answer: IStepAnswer) => {
          answersSummary.push(
            `${selectedStep.title} ${selectedStepIndex + 1}: ${answer.text}`
          );
        });
      }
    });

    return answersSummary;
  }, [steps, savedQuestionsAnswers]);

  return (
    <div ref={scrollRef} className="qa-panel-summary-answers-bar">
      {formattedAnswersSummary.map((answerText: string, index: number) => {
        const isLast = index === formattedAnswersSummary.length - 1;

        return (
          <React.Fragment key={index}>
            <div className="qa-panel-summary-answers-bar-item">
              {answerText}
            </div>
            {!isLast && (
              <div className="qa-panel-summary-answers-bar-item-dots">
                <span />
                <span />
                <span />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default QAPanelSummaryAnswersBar;
