import Image from 'next/image';

import { useApp } from '@wap-client/core';
import QaPanel from '../header/qa-panel';
import { useState } from 'react';

const MotivationBox = () => {
  const app = useApp();

  const [isShowQAPanel, setIsShowQAPanel] = useState<boolean>(false);

  const handleOpenQAPanel = () => {
    setIsShowQAPanel(true);
  };

  return (
    <>
      <div className="motivation-box">
        <div className="motivation-box-image">
          <Image
            src="/images/motivation.svg"
            alt="Motivation"
            width={357}
            height={110}
          />
        </div>
        <p>{app.settings.translations['motivationText']}</p>
        <button className="motivation-box-link" onClick={handleOpenQAPanel}>
          {app.settings.translations['motivationButton']}
        </button>
      </div>

      {isShowQAPanel && (
        <QaPanel
          isShowQAPanel={isShowQAPanel}
          setIsShowQAPanel={setIsShowQAPanel}
        />
      )}
    </>
  );
};

export default MotivationBox;
