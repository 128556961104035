import React, { useState } from 'react';
import { IResultProps } from './types';
import { functions } from '@wap-client/core';
import { Container } from '@/components/base/gridview';
import Icon from '@/components/base/icon';
import QAPanelAnswersBar from '../../summary-answers-bar/SummaryAnswersBar';
import { ICard } from '../../types';
import Card from '@/components/widgets/card';
import { useQAPanelContext } from '@/hooks/useQAPanelContext';
import CustomButton from '@/components/widgets/custom-button';

const QAPanelStepResult: React.FC<IResultProps> = ({ activeStep }) => {
  const [hiddingTransition, setHiddingTransition] = useState<boolean>(false);

  const {
    steps,
    currentStepIndex,
    handleCloseQAPanel,
    handleGoPrevStep,
    handleGoNextStep,
  } = useQAPanelContext();

  const onGoBack = () => {
    if (activeStep.isFirst) {
      handleCloseQAPanel();
    }

    setHiddingTransition(true);
    handleGoPrevStep();
  };

  const handleGoNextQuestion = () => {
    const nextIndex = currentStepIndex + 1;
    if (!steps[nextIndex]) {
      handleCloseQAPanel();
      return;
    }
    setHiddingTransition(true);
    handleGoNextStep();
  };

  return (
    <div
      className={functions.classnames(
        'qa-panel-content qa-panel-content--result qa-panel-content-transition',
        {
          'qa-panel-content-transition--hidding': hiddingTransition,
        }
      )}
    >
      <Container className="qa-panel-container" size="full">
        <header className="qa-panel-header">
          <button className="qa-panel-header-back-btn" onClick={onGoBack}>
            <Icon name="icon-arrow-left" />
          </button>
          <div className="qa-panel-header-title">{activeStep.header.title}</div>
          <button
            className="qa-panel-header-close-btn"
            onClick={handleCloseQAPanel}
          >
            <Icon name="icon-close-2" />
          </button>
        </header>
        <Container className="qa-panel-main" size="wide">
          <QAPanelAnswersBar />
          <div className="qa-panel-result">
            <div className="qa-panel-result-title">
              {activeStep.content.title}
            </div>
            {activeStep.content.cards && (
              <div className="qa-panel-result-list">
                {[...activeStep.content.cards, ...activeStep.content.cards].map(
                  (card: ICard, index: number) => (
                    <Card
                      key={index}
                      image={card.image}
                      title={card.title}
                      description={card.description}
                      like={card.like}
                      day={card.day}
                      style={{
                        '--animation-delay': `0.${index + 1}s`,
                      }}
                    />
                  )
                )}
              </div>
            )}
            <div className="qa-panel-result-redirect-box">
              <div className="qa-panel-result-redirect-box-icon">
                <Icon name="icon-star-shape" size="medium" />
              </div>
              <div className="qa-panel-result-redirect-box-text">
                Further Personalize
              </div>
              <CustomButton
                className="qa-panel-result-redirect-box-btn qa-panel-progress-btn"
                round={8}
                onClick={handleGoNextQuestion}
              >
                Next Question
              </CustomButton>
            </div>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default QAPanelStepResult;
