import { IQAData } from './types';

export const QA_PANEL_WAP_MOCK_DATA: IQAData = {
  steps: [
    {
      id: 'step-1',
      type: 'questions',
      title: 'Step',
      header: {
        title: 'Page Title',
      },
      content: {
        title: 'When do ou plan to travel?',
        multiselect: true,
        answers: [
          {
            id: 'answer-1',
            text: 'Summer',
          },
          {
            id: 'answer-2',
            text: 'Autumn',
          },
          {
            id: 'answer-3',
            text: 'Winter',
          },
          {
            id: 'answer-4',
            text: `It's not decided yet, I'm just exploring.`,
          },
        ],
      },
    },
    {
      id: 'step-2',
      type: 'questions',
      title: 'Step',
      header: {
        title: 'Page Title',
      },
      content: {
        title: 'What do you like to do the most on vacation?',
        multiselect: true,
        answers: [
          {
            id: 'answer-1',
            text: 'Swimming',
          },
          {
            id: 'answer-2',
            text: 'Exploring the city',
          },
          {
            id: 'answer-3',
            text: 'Trying new flavors',
          },
          {
            id: 'answer-4',
            text: `Having fun`,
          },
          {
            id: 'answer-5',
            text: `Visiting historical sites`,
          },
          {
            id: 'answer-6',
            text: `Being in touch with nature`,
          },
          {
            id: 'answer-7',
            text: `Exercising`,
          },
          {
            id: 'answer-8',
            text: `Gaining new expriences`,
          },
          {
            id: 'answer-9',
            text: `Other`,
          },
        ],
      },
    },
    {
      id: 'step-3',
      type: 'results',
      title: 'Result',
      badgeIconClasses: 'icon-check-mini',
      header: {
        title: 'Result',
      },
      content: {
        title: 'Here is what we have compiled for you.',
        cards: [
          {
            image: {
              src: 'https://ui-cdn-goturkiye-v3.glomil.com/storage/Root/go-turkiye-v3/Questions%20Answers/001.png',
            },
            title: 'Blue Experiences',
            description:
              'From hidden coves to iconic beaches, Türkiye is a paradise for sun and sea seekers.',
            like: true,
          },
          {
            image: {
              src: 'https://ui-cdn-goturkiye-v3.glomil.com/storage/Root/go-turkiye-v3/Questions Answers/002.png',
            },
            title: 'Kaputaş',
            description:
              'Start your journey at the heart of East and West and immerse yourself in the soul of this multi-layered  city.',
            like: true,
          },
          {
            image: {
              src: 'https://ui-cdn-goturkiye-v3.glomil.com/storage/Root/go-turkiye-v3/Questions Answers/003.png',
            },
            title: 'Experience the Fethiye Loop ',
            description:
              'Don’t miss this challenging 13-mile loop trail near Fethiye, Muğla. Stunning coastal views await along the way (approximately 6 hours).',
            like: true,
          },
        ],
      },
    },
  ],
};

// export const SLIDER_CARDS_MOCK: ISliderCardsMock = {
//   SLIDER_1: {
//     title: 'Find your\n perfect escape',
//     // description:
//     //   'Discover unforgettable cultural journeys along themed routes in Türkiye',
//     // tabs: TABS,
//     cards: CARDS,
//     sliderSettings: {
//       dots: false,
//       arrows: false,
//     },
//     sliderToolbar: true,
//     redirectButton: false,
//   },
//   SLIDER_2: {
//     title: 'Where to go',
//     tabs: TABS,
//     cards: CARDS,
//     sliderToolbar: true,
//     redirectButton: true,
//   },
//   SLIDER_3: {
//     title: 'Explore experiences around Patara',
//     cards: CARDS.slice(0, 3),
//     sliderToolbar: true,
//   },
//   SLIDER_4: {
//     tabs: TABS,
//     cards: CARDS.map((card) => ({
//       ...card,
//       title: undefined,
//     })),
//     sliderToolbar: true,
//     redirectButton: true,
//   },
//   SLIDER_5: {
//     title: 'Find your perfect escape',
//     cards: CARDS.slice(0, 3),
//     sliderToolbar: true,
//   },
//   SLIDER_6: {
//     title: 'Meet wonders of Patara',
//     cards: CARDS.slice(0, 3).map((card) => ({
//       ...card,
//       title: undefined,
//     })),
//     sliderSettings: {
//       arrows: false,
//       dots: true,
//     },
//     sliderToolbar: true,
//   },
//   SLIDER_7: {
//     cards: CARDS.slice(0, 3),
//     sliderSettings: {
//       arrows: false,
//       dots: false,
//     },
//     redirectButton: true,
//   },
//   SLIDER_8: {
//     cards: CARDS.slice(0, 3).map((card) => ({
//       ...card,
//       title: undefined,
//       description: undefined,
//     })),
//     sliderToolbar: true,
//   },
// };
